<template>
	<div>
		<p class="text-h4 text-center font-weight-bold text-uppercase mb-2">
			{{ $t('nav.contactUs') }}
		</p>
		<v-card max-width="600" class="mx-auto mt-5" flat :color="color" :dark="dark" rounded="xl">
			<v-card-title>{{ $t('contact.howReachUs') }}</v-card-title>
			<v-card-text class="text-justify">
				{{ $t('contact.enterprise.contactUsHelp1') }}
				<br />
				{{ $t('contact.enterprise.contactUsHelp2') }}
				<br />
				<v-btn text href="https://linkedin.com/company/oversos" color="#3f5fb9" target="_blank"> <v-icon>mdi-linkedin</v-icon> oversos </v-btn>
				<br />
				{{ $t('contact.enterprise.contactUsHelp3') }}
			</v-card-text>
		</v-card>
		<v-form ref="form" v-model="valid">
			<v-card max-width="600" class="mx-auto mt-5" :flat="color == 'transparent'" :color="color" :dark="dark" rounded="xl">
				<v-card-text class="pb-0">
					<v-text-field v-model="email" :label="$t('auth.email')" :rules="rules.email" required outlined rounded hide-details="auto" class="pb-3" />
					<v-text-field v-model="name" :label="$t('auth.name')" :rules="rules.name" required outlined rounded hide-details="auto" class="pb-3" />
					<v-textarea
						v-model="message"
						:label="$t('contact.message')"
						:rules="rules.message"
						:counter="messageMaxLength"
						auto-grow
						required
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
					/>

					<v-alert v-show="action.message" :type="action.success ? 'success' : 'error'">
						{{ action.message }}
					</v-alert>
				</v-card-text>

				<v-card-actions class="d-flex align-center justify-center flex-wrap pt-0">
					<v-btn class="px-4" color="primary" large rounded :loading="loading" @click="callContactSend()">
						{{ $t('contact.send') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'ContactForm',
	props: {
		color: {
			type: String,
			default: ''
		},
		dark: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			valid: false,
			email: '',
			name: '',
			message: '',
			rules: {
				email: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.indexOf('@') !== 0) || this.$t('rules.emailUsername'),
					(v) => (v && v.includes('@')) || this.$t('rules.emailAtSymbol'),
					(v) => (v && v.includes('.')) || this.$t('rules.emailPeriodSymbol'),
					(v) => (v && v.indexOf('.') <= v.length - 3) || this.$t('rules.emailDomain'),
					(v) => (v && /.+@.+\..+/.test(v)) || this.$t('rules.emailValid'),
					(v) => (v && v.length <= this.emailMaxLength) || this.$t('rules.length', { length: this.emailMaxLength })
				],
				name: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.nameMaxLength) || this.$t('rules.length', { length: this.nameMaxLength })
				],
				message: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.messageMaxLength) || this.$t('rules.length', { length: this.messageMaxLength })
				]
			},
			action: {
				success: false,
				message: ''
			}
		}
	},
	computed: {
		emailMaxLength() {
			return 255
		},
		nameMaxLength() {
			return 128
		},
		messageMaxLength() {
			return 1024 * 10
		},
		...mapGetters({
			currentUser: 'user/currentUser'
		})
	},
	created() {
		this.email = this.currentUser.email ? this.currentUser.email : ''
		this.name = this.currentUser.name ? this.currentUser.name.full : ''
	},
	methods: {
		checkIsSpam() {
			if (this.message.includes('Link - http') || this.message.includes('Enlace - http')) {
				this.action = { success: false, message: this.$t('contact.enterprise.messageIsSpam') }
				return true
			}
			return false
		},
		callContactSend() {
			this.$refs.form.validate()
			if (!this.valid) return
			if (this.checkIsSpam()) return
			this.loading = true
			this.contactSend({
				email: this.email,
				name: this.name,
				message: this.message
			}).then(({ success, response }) => {
				this.action = { success, message: response }
				this.loading = false
			})
		},
		...mapActions('data', ['contactSend'])
	}
}
</script>
